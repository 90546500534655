export function useEmailFlow() {
  const nuxtApp = useNuxtApp()

  const confirmEmailJwt = async (
    confirmationToken: string,
    userGuid: string
  ) => {
    try {
      await nuxtApp.$api.user.confirmEmailJwt(userGuid, confirmationToken)
      return createSuccessResponse()
    } catch (e) {
      return createUnknownResponse('Could not confirm email', e)
    }
  }

  /**
   * @deprecated
   */
  const confirmEmail = async (confirmationToken: string) => {
    // TODO this will break when or if the PATCH CALL do not return the user object.
    const user = await nuxtApp.$api.user.confirmEmail(confirmationToken)

    return user
  }

  const sendAccessGrantedReceiptEmail = async (
    userId: number,
    receiptPapers: string
  ) => {
    try {
      await nuxtApp.$api.user.sendAccessGrantedReceiptEmail(
        userId,
        receiptPapers
      )

      return createSuccessResponse()
    } catch (e) {
      return createUnknownResponse('Could not send access granted receipt', e)
    }
  }

  return {
    confirmEmailJwt,
    confirmEmail,
    sendAccessGrantedReceiptEmail,
  }
}
