import { ApiResponse } from '~/typesManual/apiResponse'

export const createSuccessResponse = () => {
  const response: ApiResponse = {
    success: true,
  }

  return response
}

export const createMissingParamResponse = (message: string) => {
  const error: ApiResponse = {
    success: false,
    code: 422,
    error: new Error(message),
  }

  return error
}

export const createUnknownResponse = (message: string, cause?: unknown) => {
  const error: ApiResponse = {
    success: false,
    code: 500,
    error: new Error(message, {
      cause: cause,
    }),
  }

  return error
}
